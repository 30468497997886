import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import EmailIcon from "@material-ui/icons/MailOutline"
import CheckIcon from "@material-ui/icons/Check"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"

import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme) => ({
  subscribeBox: {
    padding: theme.spacing(4, 4),
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    position: "relative",
  },
  successMessage: {
    color: green[500],
    verticalAlign: "middle",
    display: "inline-flex",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  subscribeBoxHeading: {
    marginTop: 0,
  },
}))

const NewsletterCta = ({ siteTitle, toggleDrawer }) => {
  const classes = useStyles()

  const handleEmailReturn = (event) => {
    if (event.key === "Enter") {
      signUp(email)
      event.preventDefault()
    }
  }

  const handleEmailChange = (event) => {
    if (event.target.value !== "") {
      setError(false)
    }
    setEmail(event.target.value)
  }

  const signUp = (inputEmail) => {
    if (inputEmail === "") {
      setError(true)
      return
    }
    setLoading(true)
    addToMailchimp(inputEmail) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        setLoading(false)
        setSuccess(true)
      })
  }

  const handleSignup = (e) => {
    e.preventDefault()
    signUp(email)
  }

  const [email, setEmail] = useState(``)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  return (
    <Paper className={classes.subscribeBox}>
      <Typography
        variant="h4"
        gutterBottom
        className={classes.subscribeBoxHeading}
      >
        Get the latest recipes and insights!
      </Typography>

      <Typography variant="body1" gutterBottom>
        Subscribe to our mailing list for the latest dessert recipes, tips and
        advice.
      </Typography>

      <TextField
        id="email-field"
        label="Your Email"
        margin="normal"
        value={email}
        onChange={handleEmailChange}
        onKeyPress={handleEmailReturn}
        error={error}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.buttonWrapper}>
        {success ? (
          <>
            <Typography
              variant="button"
              align="center"
              color="success"
              display="block"
              gutterBottom
            >
              <div className={classes.successMessage}>
                <Box mr={1}>
                  <CheckIcon />
                </Box>
                Subscribed
              </div>
            </Typography>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              disabled={loading}
              onClick={handleSignup}
            >
              Subscribe
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </>
        )}
      </div>
    </Paper>
  )
}
export default NewsletterCta
